<template>
  <Page>
    <AppBar title="Invoice" />
    <loading-circle v-if="loading" />
    <v-snackbar v-model="snackbar" :timeout="3000" :color="color">
      {{ snackbarText }}
    </v-snackbar>
    <v-snackbar v-model="copyEmail" :timeout="3000" color="success" top right>
      Email copied successfully
    </v-snackbar>

    <v-container v-if="!loading && transactionReceipt">
      <v-card tile style="padding: 10px; margin-top: 50px; margin-bottom: 20px">
        <div
          class="white--text align-start bg-primary d-flex flex-row justify-space-between"
          style="background-color: #1f6070; margin-bottom: 30px"
          width="100%"
          height="100px"
        >
          <v-card-title
            class="text-center justify-center"
            style="
              width: 100%;
              font-size: 20px !important;
              font-weight: 400 !important;
              align-self: flex-start;
            "
          >
            Transaction Receipt
          </v-card-title>
        </div>

        <div>
          <v-row v-if="checkMobile" style="margin-left: 0px; margin-right: 0px">
            <v-col cols="12" class="text-left">
              <v-img
                :src="logo"
                max-width="200px"
                width="200px"
                v-if="logo"
              ></v-img>
            </v-col>
          </v-row>

          <div
            class="black--text d-flex flex-column align-left col-12"
            style="margin-top: 10px; margin-left: 0px; margin-right: 0px"
          >
            <p class="subtitle-1 mb-0 text-left" style="padding: 0px">
              Ref #: {{ transactionReceipt.reference }}
            </p>
            <p class="subtitle-1 mb-0 text-left" style="padding: 0px">
              Created: {{ transactionReceipt.transdate }}
            </p>
            <p class="subtitle-1 mb-0 text-left" style="padding: 0px">
              NPI:
              {{ transactionReceipt.cryonpi ? transactionReceipt.cryonpi : '' }}
            </p>
          </div>
        </div>

        <v-row
          style="margin-top: 0px; margin-left: 0px; margin-right: 0px"
          v-if="!checkMobile"
        >
          <div
            class="col-6 black--text d-flex flex-column align-right justify-center"
          >
            <v-col cols="6" class="text-left">
              <v-img
                :src="logo"
                max-width="200px"
                width="200px"
                v-if="logo"
              ></v-img>
            </v-col>
          </div>
          <div
            class="black--text d-flex flex-column align-right col-6"
            style="margin-left: 0px; margin-right: 0px"
          >
            <p class="subtitle-1 mb-0 text-right" style="padding: 0px">
              Ref #: {{ transactionReceipt.reference }}
            </p>
            <p class="subtitle-1 mb-0 text-right" style="padding: 0px">
              Created: {{ transactionReceipt.transdate }}
            </p>
            <p class="subtitle-1 mb-0 text-right" style="padding: 0px">
              NPI:
              {{ transactionReceipt.cryonpi ? transactionReceipt.cryonpi : '' }}
            </p>
          </div>
        </v-row>
        <v-row style="margin-top: 20px; margin-left: 0px; margin-right: 0px">
          <div
            class="col-6 black--text d-flex flex-column align-right justify-center"
          >
            <p class="subtitle-1 mb-0 text-left" style="padding: 0px">
              {{ transactionReceipt.facility }}
            </p>
            <p class="subtitle-1 mb-0 text-left" style="padding: 0px">
              5000 Meridian Blvd, Suite 250
            </p>
            <p class="subtitle-1 mb-0 text-left" style="padding: 0px">
              Franklin, TN 37067
            </p>
          </div>
          <div
            class="col-6 black--text d-flex flex-column align-right justify-center"
          >
            <p class="subtitle-1 mb-0 text-right" style="padding: 0px">
              {{ transactionReceipt.firstname }}
              {{ transactionReceipt.lastname }}
            </p>
            <p class="subtitle-1 mb-0 text-right" style="padding: 0px">
              {{ transactionReceipt.email }}
            </p>
            <p class="subtitle-1 mb-0 text-right" style="padding: 0px">
              {{ transactionReceipt.address1 }}
            </p>
            <p class="subtitle-1 mb-0 text-right" style="padding: 0px">
              {{ transactionReceipt.city }}, {{ transactionReceipt.state }}
              {{ transactionReceipt.zip }}
            </p>
          </div>
        </v-row>

        <div style="margin-top: 5px; margin-left: 10px; margin-right: 10px">
          <v-row>
            <v-col cols="6" class="text-left">
              <p style="font-weight: 600; margin-bottom: 0; align: left">
                Payment Method
              </p>
            </v-col>

            <v-col cols="6" class="text-right">
              <p
                style="
                  font-weight: 600;
                  margin-bottom: 0;
                  align: right;
                  margin-right: 20px;
                "
              >
                Amount
              </p>
            </v-col>
          </v-row>
        </div>
        <div
          style="
            border-top: 1px solid gray;
            margin-top: 5px;
            margin-left: 10px;
            margin-right: 10px;
          "
        >
          <v-row style="margin-top: 5px">
            <v-col cols="6" class="text-left">
              <p style="font-weight: 400; margin-bottom: 0; align: left">
                Credit Card
              </p>
            </v-col>

            <v-col cols="6" class="text-right">
              <p
                style="
                  font-weight: 400;
                  margin-bottom: 0;
                  align: right;
                  margin-right: 20px;
                "
              >
                {{ transactionReceipt.total }}
              </p>
            </v-col>
          </v-row>
        </div>
        <div
          style="
            border-top: 1px solid gray;
            margin-top: 5px;
            margin-left: 10px;
            margin-right: 10px;
          "
        >
          <v-row style="margin-top: 5px">
            <v-col cols="6" class="text-left">
              <p style="font-weight: 600; margin-bottom: 0; align: left">
                Item
              </p>
            </v-col>

            <v-col cols="6" class="text-right">
              <p
                style="
                  font-weight: 600;
                  margin-bottom: 0;
                  align: right;
                  margin-right: 20px;
                "
              >
                Price
              </p>
            </v-col>
          </v-row>
        </div>
        <div
          class="mt-4"
          style="
            border-top: 1px solid gray;
            margin-top: 5px;
            margin-left: 10px;
            margin-right: 10px;
          "
        >
          <v-row style="margin-top: 5px">
            <v-col cols="8" class="text-left">
              Invoice Payment - {{ transactionReceipt.invoicepid }} -
              {{ transactionReceipt.notes }} {{ transactionReceipt.id }} -
              Timeframe: to - CPT:
            </v-col>

            <v-col cols="4" class="text-right">
              <p
                style="
                  font-weight: 400;
                  margin-bottom: 0;
                  align: right;
                  margin-right: 20px;
                "
              >
                {{ transactionReceipt.total }}
              </p>
            </v-col>
          </v-row>
        </div>
        <div
          class="mt-4"
          style="
            border-top: 1px solid gray;
            margin-top: 5px;
            margin-left: 10px;
            margin-right: 10px;
          "
        >
          <v-row style="margin-top: 5px">
            <v-col cols="12" class="text-right">
              <p
                style="
                  font-weight: 600;
                  margin-bottom: 0;
                  align: right;
                  margin-right: 20px;
                "
              >
                Total Paid: {{ transactionReceipt.total }}
              </p>
            </v-col>
          </v-row>
        </div>
        <div class="mt-4">
          <v-row style="margin-top: 5px">
            <v-col cols="12" class="text-center">
              <v-btn
                color="primary white--text"
                :style="styleCustomButton"
                @click="toGoHome"
              >
                Login
              </v-btn>
            </v-col>
          </v-row>
        </div>
      </v-card>
    </v-container>
  </Page>
</template>
<style>
.mdi-close {
  visibility: hidden;
}
.v-btn--icon {
  visibility: hidden;
}
</style>
<script>
import { api, auth } from '../sharedPlugin';
import LoadingCircle from '@/components/LoadingCircle.vue';
import device from '../utils/device';
export default {
  components: { LoadingCircle },
  data: () => ({
    loading: false,
    invoiceLoading: false,
    transactionReceipt: null,
    styleCustomButton:
      device() === 'mobile'
        ? {
            'font-size': '3.6vw',
            width: '200px',
            height: '60px'
          }
        : {
            width: '200px',
            height: '60px',
            'font-size': '20px'
          }
  }),
  created() {
    if (this.$route?.params?.id) {
      this.getClientLogo();
      this.getTransactionReceipt(this.$route?.params?.id);
    }
  },
  computed: {
    checkMobile() {
      return device() === 'mobile' ? true : false;
    }
  },
  methods: {
    ...auth.mapMethods(['logged']),
    toGoHome() {
      this.$router.push({ name: 'CalendarPage' });
    },
    async getClientLogo() {
      this.loading = true;
      await api.Users.getClientLogo()
        .then((res) => {
          if (res.data && res.data.length > 0) {
            this.logo = res.data[0].logoLink
              ? res.data[0].logoLink
              : res.data[0].logo;
          }
        })
        .finally(() => (this.loading = false));
    },
    async getTransactionReceipt(id) {
      this.loading = true;
      await api.Cryo.getTransactionReceipt(id)
        .then((res) => {
          this.transactionReceipt = res[0];
        })
        .finally(() => (this.loading = false));
    }
  }
};
</script>
